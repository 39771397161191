// src/App.js
// start locally with REACT_APP_BASE_PATH=http://0.0.0.0:8000 npm start
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
function SmsPage() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Zerophishing SMS Scanner</h1>
        <div className="sms-content">
          <p>Are you concerned a text you received may be a scam?</p>
          <p>Send a screenshot to <span className="phone-number">(866) 990-6283</span> and we will investigate and get back to you</p>
          <p><small>By sending a message, you opt in to receiving responses from our SMS service. We will never sell your phone number or send you marketing messages.</small></p>
          <Link to="/" className="home-link">Back to URL Scanner</Link>
        </div>
      </header>
    </div>
  );
}

function App() {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [activeTab, setActiveTab] = useState('tab1'); // Manage active tab

  const BASE_PATH = process.env.REACT_APP_BASE_PATH || 'https://container-service-1.26jqins83oj16.us-east-1.cs.amazonlightsail.com';

  const getApiKey = async () => {
    try {
      const response = await fetch(`${BASE_PATH}/get_api_key`);
      const data = await response.json();
      setApiKey(data.api_key);
    } catch (error) {
      console.error('Error fetching API key:', error);
    }
  };

  const checkUrl = async (urlToCheck) => {
    // This function takes in an argument urlToCheck because there is some client-side processing of the url between when the user inputs it and when it is sent to the server
    setLoading(true);
    try {
      console.log('Checking URL:', urlToCheck);
      const response = await fetch(`${BASE_PATH}/classify?url=${urlToCheck}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('Response:', response);
      const data = await response.json();
      console.log('data:', data.detail);
      if (!response.ok) {
        // If the response status is not OK (e.g., 400, 500), read the error message
        console.log('Setting error:', data.detail);
        setResult({ error: data.detail });
      } else {
        console.log('Setting result:', data);
        setResult(data);
      }

    } catch (error) {
      console.error('Error checking URL:', error);
      setResult({ error: 'Failed to check URL' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let urlToCheck = url;
    if (!/^https?:\/\//i.test(url)) {
      urlToCheck = `http://${url}`;
    }

    // Update the text box and state with the urlToCheck
    setUrl(urlToCheck);

    // Send the URL to the server for checking
    checkUrl(urlToCheck);
  };

  const handleNewScan = () => {
    setUrl('');
    setResult(null);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  React.useEffect(() => {
    getApiKey();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/sms" element={<SmsPage />} />
        <Route path="/" element={
          <div className="App">
            {result ? (
              <div class="topnav-right">
                <button 
                  onClick={handleNewScan} 
                  className="new-scan-button" 
                  style={{ 
                    position: 'absolute', 
                    right: '0', 
                    // marginRight: '20px', 
                    // marginBottom: '1em' 
                  }}>
                  Scan Another URL
                </button>
              </div>
            ) : (
              <div class="topnav-right">
              </div>
            )}
            <header className="App-header">
              <h1>Zerophishing URL Scanner</h1>
              {!result ? (
                <div>
                  <form onSubmit={handleFormSubmit}>
                    <input
                      type="text"
                      placeholder="Enter URL to scan"
                      value={url}
                      onChange={handleInputChange}
                      required
                      className="url-input"
                    />
                    <button type="submit" className="scan-button">Scan URL</button>
                    {loading && (
                      <div className="spinner-container">
                        <div className="spinner"></div>
                      </div>
                    )}
                  </form>

                  <p>
                    An AI-powered malicious website detector
                  </p>
                  {/* <p>
                    Sign up for an API key at <a className='nice-link' href="https://zero-phishing.com">zero-phishing.com</a>
                  </p> */}
                </div>
              ) : (
                <div className="result-display">
                  {result.error ? (
                    <p>{result.error}</p>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginBottom: '1em', marginLeft: '0.5em', marginRight: '0.5em' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            wordWrap: 'break-word'
                          }}
                        >
                          <p style={{ fontWeight: 'bold', fontSize: '1.5em', textAlign: 'center' }}>
                            {url.length <= 20 ? url : url.slice(0, 20) + '...'} is&nbsp;
                          </p>

                          <p
                            style={{
                              fontWeight: 'bold',
                              color:
                                result.url_classification.classification === 'Malicious'
                                  ? 'red'
                                  : result.url_classification.classification === 'Inactive'
                                  ? 'yellow'
                                  : 'green',
                              fontSize: '1.5em',
                              textAlign: 'center',
                            }}
                          >
                            {result.url_classification.classification}
                          </p>
                        </div>

                        <p>{result.url_classification.justification}</p>
                      </div>

                      <div style={{ display: 'flex', gap: '1em' }}>
                        <div
                          style={{
                            flex: '1',
                            marginLeft: '0.5em',
                            marginRight: '0.5em',
                            marginBottom: '0.5em',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div className="tabs">
                            <button
                              className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
                              onClick={() => handleTabClick('tab1')}
                            >
                             
                              Analysis Results

                            </button>
                            <button
                              className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
                              onClick={() => handleTabClick('tab2')}
                            >
                              Domain Information
                            </button>
                            <button
                              className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
                              onClick={() => handleTabClick('tab3')}
                            >
                              Website Summary
                            </button>
                          </div>

                          <div className="tab-content" style={{ flex: '1', overflowY: 'auto' }}>

                            {activeTab === 'tab1' && (
                              <table className="display-table">
                                <tbody>
                                  <tr>
                                    <td>Url</td>
                                    <td>{url}</td>
                                  </tr>
                                  <tr>
                                    <td>Fully Qualified Domain Name</td>
                                    <td>{result.domain_data.fqdn}</td>
                                  </tr>
                                  <tr>
                                    <td>Registered Domain Name</td>
                                    <td>{result.domain_data.rdn}</td>
                                  </tr>
                                  <tr>
                                    <td>Domain Rank</td>
                                    <td>{result.domain_data.domain_rank_magnitude_string}</td>
                                  </tr>
                                  <tr>
                                    <td>Domain is Webhosting Service</td>
                                    <td>{result.url_classification.is_webhosting_domain ? 'True' : 'False'}</td>
                                  </tr>
                                  <tr>
                                    <td>Registrant Name</td>
                                    <td>{result.domain_data.registrant_name || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td>Registrar Name</td>
                                    <td>{result.domain_data.registrar_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Domain Expires</td>
                                    <td>{result.domain_data.expires || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td>Domain Last Updated</td>
                                    <td>{result.domain_data.updated || 'N/A'}</td>
                                  </tr>
                                  <tr>
                                    <td>Domain Created</td>
                                    <td>{result.domain_data.created || 'N/A'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            )}

                            {activeTab === 'tab2' && (
                              <table className="display-table">
                                <tbody>
                                  <tr>
                                    <td>Impersonation Strategy</td>
                                    <td>{result.url_classification.impersonation_strategy || 'No Impersonation'}</td>
                                  </tr>
                                  <tr>
                                    <td>Credential Theft Strategy</td>
                                    <td>{result.url_classification.credential_theft_strategy || 'No Credential Theft'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                           {activeTab === 'tab3' && result.url_classification.page_summary}

                          </div>
                        </div>

                        {result.page_data.base64_encoded_image && (
                          <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img
                                src={`data:image/png;base64,${result.page_data.base64_encoded_image}`}
                                alt="Scan result"
                                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </header>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;
